.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-color: #011627;
  background: #011627 url(../../../public/background.gif) no-repeat;
  background-size: cover;
  background-position: center;
}

.loginBox {
  background: rgba(1, 22, 39, 0.85);
  padding: 30px;
  border-radius: 0 0 10px 10px;
  width: 300px;
  max-width: 95%;
}

.loginBox label {
  display: block;
  color: #d6deeb;
  font-weight: bold;
  padding: 0.5rem 0;
}

.loginBox input {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0.8rem;
  color: #d6deeb;
  border: 2px solid #865dff72;
  border-radius: 3px;
  background-color: #2e2040;
  padding: 0.5rem;
  transition: 0.3s;

  &:focus {
    border-color: #865dff;
    outline: none;
  }

  &::placeholder {
    color: #d6deebb4;
  }
}

.loginBox p {
  color: red;
  transition: 0.3s;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0;
}

.loginBox button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: none;
  background-color: #865dff;
  color: #d6deeb;
}

.tech {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #865dff;
  color: #d6deeb;
  padding: 0.5rem;
  outline: none;
  border: none;
}

.cameraBox {
  border-radius: 10px 10px 0 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
}
