.container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #011627;
}

.teamContainer {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
  align-content: center;

  width: 100vw;
}

.confirmButton {
  border-style: none;
  width: 50%;
  height: 40px;
}

h1 {
  color: white;
}
